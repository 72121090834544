import react, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table'
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom'
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useParams } from 'react-router-dom'




function EditUser() {
    const { id } = useParams()
    let [data, setData] = useState({})
    let [first, setFirst] = useState("")
    let [last, setLast] = useState("")
    let [nickname, setNickname] = useState("")
    let [trainer, setTrainer] = useState("")
    let [loading, setLoading] = useState(true)


    useEffect(() => {
        const fetchData = async () => {
            const { data: response } = await axios.get(`${process.env.REACT_APP_API}/edit/${id}`)
            setData(response)
            setFirst(response.first)
            setLast(response.last)
            setNickname(response.nickname)
            setTrainer(response.trainer)
        }
        fetchData()
        setLoading(false)
    }, [id])

    // console.log(data)


    const submitPost = async (e) => {
        e.preventDefault();

        axios.put(`${process.env.REACT_APP_API}/edit/${id}`, {
            first: first,
            last: last,
            nickname: nickname,
            trainer: trainer
        })
            .then(res => {
                axios.post('https://www.woahtracker.com/EditUser/', { entry: res.data.id })
                console.log(res);
                // console.log(res.data)
                window.location = `/UserList`
            })
            .catch(error => {
                console.log(error.response)
            })

    }

    return (
        <div>
            {loading && <div className='spinner'>
                <Spinner animation="border" role="status" size='lg'>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>}
            {!loading && (<>
                <h1>Edit {data.location} User</h1>
                <form onSubmit={(e) => submitPost(e)}>
                    <Table responsive striped bordered hover size="sm" style={{ width: "95%", margin: "auto" }}>
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Nickname</th>
                                <th>Trainer</th>
                                <th colSpan={2}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Form.Control size="sm" type="text" onChange={(e) => setFirst(e.target.value)} value={first} required />
                                    <Form.Text >
                                    </Form.Text>
                                </td>
                                <td>
                                    <Form.Control size="sm" type="text" onChange={(e) => setLast(e.target.value)} value={last} required />
                                    <Form.Text >
                                    </Form.Text>
                                </td>
                                <td>
                                    <Form.Control size="sm" type="text" onChange={(e) => setNickname(e.target.value)} value={nickname} required />
                                    <Form.Text >
                                    </Form.Text>
                                </td>
                                <td>
                                    <Form.Select size="sm" aria-label="Is Trainer?" onChange={(e) => setTrainer(e.target.value)} value={trainer}>
                                        <option>No</option>
                                        <option>Yes</option>
                                    </Form.Select>
                                </td>
                                <td>
                                    <Button size="sm" type="submit"  >Update</Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </form>
            </>)
            }
        </div>
    )


}

export default EditUser