import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Button } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { BrowserRouter as Router, Link, Navigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { useContext } from "react";
import react, { useEffect, useState } from "react";
import axios from "axios";

function NavBar() {
    const { logoutUser } = useContext(AuthContext)
    const { user } = useContext(AuthContext)
    return (
        <Navbar collapseOnSelect key="false" bg="dark" variant='dark' expand="false" className="mb-3">
            <Container fluid>
                <div className="NavLogOut"> {user ? (
                    <>
                        <Navbar.Brand href="https://www.woahtracker.com/dashboard">Workout Tracker - {user.username}</Navbar.Brand>
                        <Button size="sm" onClick={logoutUser} variant='dark' >Logout</Button>
                    </>
                ) : (
                    <>
                        <Navbar.Brand href="https://www.woahtracker.com/dashboard">Workout Tracker</Navbar.Brand>
                    </>)}
                </div>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-false`} />
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-false`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-false`}
                    placement="end"
                >
                    <Offcanvas.Body collapseOnSelect>
                        <Nav className="justify-content-end flex-grow-1 pe-3" >
                            <div> {user ? (
                                <>
                                    <Nav.Item >
                                        <Nav.Link as={Link} to="/dashboard" eventKey="/Dashboard" >Dashboard</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link as={Link} to="/UserList" eventKey="/UserList" >User List</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link as={Link} to="/WorkoutList" eventKey="/WorkoutList" >Workout List</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link as={Link} to="/resetPassword" eventKey="/resetPassword" >Reset Password</Nav.Link>
                                    </Nav.Item>
                                    {/* <div className='navButton'>
                                        <Button size="sm" onClick={logoutUser} variant='dark' >Logout</Button>
                                    </div> */}
                                    {/* <div className='navButton'>
                                        <Link to={`/resetPassword`}>
                                            <Button size="sm" variant='dark' >Reset Password</Button >
                                        </Link>
                                    </div> */}
                                </>
                            ) : (
                                <>
                                    {/* <Link to="/login" eventKey="/Login">Login</Link>
                                    <Link to="/register">Register</Link> */}
                                    <Nav.Item >
                                        <Nav.Link as={Link} to="/login" eventKey="/Login" >Log In</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link as={Link} to="/register" eventKey="/Register" >Register</Nav.Link>
                                    </Nav.Item> */}
                                </>
                            )}
                            </div>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container >
        </Navbar >
    );
}

export default NavBar;