import react, { useEffect, useState, useContext } from "react";
import Form from 'react-bootstrap/Form'
import Check from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table'
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom'
import AuthContext from "../context/AuthContext";
import Accordion from 'react-bootstrap/Accordion';


function WorkoutList() {

    let [loading, setLoading] = useState(true)
    let [chowList, setUserList] = useState({})
    let { user } = useContext(AuthContext)
    let adminId = user.user_id
    let [adminData, setAdminData] = useState({})
    let [todayChow, setChowToday] = useState({})
    let [workoutList, setWorkoutList] = useState({})
    let [stateList, setStateList] = useState({})
    let [locationList, setLocationList] = useState({})
    let [title, setTitle] = useState("")
    let [round, setRound] = useState("")
    let [date, setDate] = useState("")
    let [all1Title, setAll1Title] = useState("")
    let [all2Title, setAll2Title] = useState("")
    let [all3Title, setAll3Title] = useState("")
    let [all4Title, setAll4Title] = useState("")
    let [all5Title, setAll5Title] = useState("")
    let [all6Title, setAll6Title] = useState("")
    let [all7Title, setAll7Title] = useState("")
    let [all8Title, setAll8Title] = useState("")
    let [all9Title, setAll9Title] = useState("")
    let tableRow
    let extraRow
    const [showText, setShowText] = useState("Show")
    let [extraRowBool, setExtraRowBool] = useState("")
    const [showExtraRow, setShowExtraRow] = useState(false)
    const handleClose = () => setShowExtraRow(false)
    const handleShow = () => setShowExtraRow(true)
    const [showBool, setShowBool] = useState(false)
    const [previous, setPrevious] = useState("0")
    function toggleText() {
        setShowBool(!showBool)
        if (showBool == false) {
            setExtraRowBool(extraRow)
            setShowText("Hide")
        } else {
            setExtraRowBool("")
            setShowText("Show")
        }

    }
    let shareText = ""
    let shared = ""
    let [shareScore, setShareScore] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const { data: adminData } = await axios.get(`${process.env.REACT_APP_API}/adminUsers/${adminId}/`)
            setAdminData(adminData)
            const { data: response } = await axios.get(`${process.env.REACT_APP_API}/chow_list/`)
            setUserList(response)
            setLoading(false);
        }
        fetchData()
    }, [])

    if (adminData) {
        shared = adminData.share_score
        if (shared == 'No') {
            shareText = "Your scores are private"
        } else {
            shareText = "Your scores are being shared with other locations"
        }
    }

    let currentChow = Array.from(chowList)
    let chows = ''
    let state = null
    let location = null
    let lastDate = null
    let dateStarter = new Date()
    let all1Form
    let all2Form
    let all3Form
    let all4Form
    let all5Form
    let all6Form
    let all7Form
    let all8Form
    let all9Form

    // console.log(adminData)
    if (currentChow.length > 0) {
        state = adminData.state
        location = adminData.location
        lastDate = currentChow[0].start
        // lastDate = "2022-10-24"
    }

    function getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    let thisMonday = getMonday(new Date());

    let day = String(thisMonday.getDate()).padStart(2, '0')
    let month = String(thisMonday.getMonth() + 1).padStart(2, '0')
    let year = thisMonday.getFullYear()
    let dateValidation = `${year}-${month}-${day}`
    let activeButton = null
    let activeTitle = null
    let activeStation = null
    let activeDate = null
    let activeRepeat = null
    // console.log(dateValidation)
    let repeatList = []
    let repeatOptionList = null
    // console.log(currentChow)
    if (dateValidation === lastDate) {
        activeTitle = <Form.Control size="sm" type="text" onChange={(e) => setTitle(e.target.value)} disabled required />
        activeStation = <Form.Control size="sm" type="number" onChange={(e) => setRound(e.target.value)} disabled required />
        activeButton = {} = <Button size="sm" type="submit" disabled >Add</Button>
        activeDate = `${dateValidation} is Active`
    } else {
        activeTitle = <Form.Control size="sm" type="text" onChange={(e) => setTitle(e.target.value)} required />
        activeStation = <Form.Select required size="sm" onChange={(e) => setRound(e.target.value)}>
            <option value="" hidden>Select</option>
            <option value="1" >1</option>
            <option value="2" >2</option>
            <option value="3" >3</option>
            <option value="4" >4</option>
            <option value="5" >5</option>
            <option value="6" >6</option>
            <option value="7" >7</option>
            <option value="8" >8</option>
            <option value="9" >9</option>
            <option value="All" >All</option>
        </ Form.Select>
        if (round != "") {
            for (let i = 0; i < currentChow.length; i++) {
                if (currentChow[i].round == round && currentChow[i].previous == null) {
                    repeatList.push({ id: currentChow[i].id, title: currentChow[i].title })
                }
            }
            if (repeatList.length > 0) {
                repeatOptionList = repeatList.map((station, index) => {
                    return <>
                        <option value={station.id} >{station.title}</option>
                    </>
                })
                activeRepeat = <Form.Select size="sm" type="number" required onChange={(e) => setPrevious(e.target.value)} >
                    <option value={0} hidden>Select Previous Attempt</option>
                    {repeatOptionList}
                    <option value={0}>No Previous Attempt</option>
                </ Form.Select>
            } else {
                repeatOptionList = <option value={null} >"No Previous Attempts"</option>
                activeRepeat = <Form.Select required size="sm" disabled>
                    {repeatOptionList}
                </Form.Select>
            }
        }
        activeButton = {} = <Button size="sm" type="submit" >Add</Button>
        activeDate = dateValidation
        if (round === 'All') {
            all1Form = <tr>
                <td>1</td>
                <td><Form.Control size="sm" type="text" onChange={(e) => setAll1Title(e.target.value)} required />
                    <Form.Text >
                    </Form.Text>
                </td>
                <td></td>
                <td></td>
            </tr>
            all2Form = <tr>
                <td>2</td>
                <td><Form.Control size="sm" type="text" onChange={(e) => setAll2Title(e.target.value)} required />
                    <Form.Text >
                    </Form.Text>
                </td>
                <td></td>
                <td></td>
            </tr>
            all3Form = <tr>
                <td>3</td>
                <td><Form.Control size="sm" type="text" onChange={(e) => setAll3Title(e.target.value)} required />
                    <Form.Text >
                    </Form.Text>
                </td>
                <td></td>
                <td></td>
            </tr>
            all4Form = <tr>
                <td>4</td>
                <td><Form.Control size="sm" type="text" onChange={(e) => setAll4Title(e.target.value)} required />
                    <Form.Text >
                    </Form.Text>
                </td>
                <td></td>
                <td></td>
            </tr>
            all5Form = <tr>
                <td>5</td>
                <td><Form.Control size="sm" type="text" onChange={(e) => setAll5Title(e.target.value)} required />
                    <Form.Text >
                    </Form.Text>
                </td>
                <td></td>
                <td></td>
            </tr>
            all6Form = <tr>
                <td>6</td>
                <td><Form.Control size="sm" type="text" onChange={(e) => setAll6Title(e.target.value)} required />
                    <Form.Text >
                    </Form.Text>
                </td>
                <td></td>
                <td></td>
            </tr>
            all7Form = <tr>
                <td>7</td>
                <td><Form.Control size="sm" type="text" onChange={(e) => setAll7Title(e.target.value)} required />
                    <Form.Text >
                    </Form.Text>
                </td>
                <td></td>
                <td></td>
            </tr>
            all8Form = <tr>
                <td>8</td>
                <td><Form.Control size="sm" type="text" onChange={(e) => setAll8Title(e.target.value)} required />
                    <Form.Text >
                    </Form.Text>
                </td>
                <td></td>
                <td></td>
            </tr>
            all9Form = <tr>
                <td>9</td>
                <td><Form.Control size="sm" type="text" onChange={(e) => setAll9Title(e.target.value)} required />
                    <Form.Text >
                    </Form.Text>
                </td>
                <td></td>
                <td></td>
            </tr>
        }
    }
    if (currentChow) {
        chows = currentChow.map((chow, index) => {
            if (chow.round == "All") {
                extraRow = <>
                    <tr key={index} >
                        <td>All - 1</td>
                        <td>{chow.all_1_title}</td>
                        <td colSpan={2}>
                        </td>
                    </tr>
                    <tr>
                        <td>All - 2</td>
                        <td>{chow.all_2_title}</td>
                        <td colSpan={2}>
                        </td>
                    </tr>
                    <tr>
                        <td>All - 3</td>
                        <td>{chow.all_3_title}</td>
                        <td colSpan={2}>
                        </td>
                    </tr>
                    <tr>
                        <td>All - 4</td>
                        <td>{chow.all_4_title}</td>
                        <td colSpan={2}>
                        </td>
                    </tr>
                    <tr>
                        <td>All - 5</td>
                        <td>{chow.all_5_title}</td>
                        <td colSpan={2}>
                        </td>
                    </tr>
                    <tr>
                        <td>All - 6</td>
                        <td>{chow.all_6_title}</td>
                        <td colSpan={2}>
                        </td>
                    </tr>
                    <tr>
                        <td>All - 7</td>
                        <td>{chow.all_7_title}</td>
                        <td colSpan={2}>
                        </td>
                    </tr>
                    <tr>
                        <td>All - 8</td>
                        <td>{chow.all_8_title}</td>
                        <td colSpan={2}>
                        </td>
                    </tr>
                    <tr>
                        <td>All - 9</td>
                        <td>{chow.all_9_title}</td>
                        <td colSpan={2}>
                        </td>
                    </tr>
                    <br></br>
                </>
                tableRow = <>
                    <tr key={chow.id} >
                        <td>
                            <Link onClick={toggleText} className="descLink">
                                {chow.round} (Click to {showText})
                            </Link>
                        </td>
                        <td>{chow.title}</td>
                        <td>{chow.start}</td>
                        <td>
                            <Link to={`/WorkoutDetail/${chow.id}/${state}/${location}`}>
                                <Button size="sm" type="submit">View Log</Button>
                            </Link>
                        </td>
                        {/* <td><Button size="sm" type=""  >Edit</Button></td> */}
                    </tr>
                    {extraRowBool}
                </>
            } else {
                tableRow =
                    <tr key={chow.id} >
                        <td>{chow.round}</td>
                        <td>{chow.title}</td>
                        <td>{chow.start}</td>
                        <td>
                            <Link to={`/WorkoutDetail/${chow.id}/${state}/${location}`}>
                                <Button size="sm" type="submit">View Log</Button>
                            </Link>
                        </td>
                        {/* <td><Button size="sm" type=""  >Edit</Button></td> */}
                    </tr>
            }
            return <>
                {tableRow}
            </>
        })
    }
    const submitPost = async (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API}/chow_list/`, {
            title: title,
            round: round,
            start: dateValidation,
            previous: previous,
            all_1_title: all1Title,
            all_2_title: all2Title,
            all_3_title: all3Title,
            all_4_title: all4Title,
            all_5_title: all5Title,
            all_6_title: all6Title,
            all_7_title: all7Title,
            all_8_title: all8Title,
            all_9_title: all9Title
        })
            .then(res => {
                window.location.reload()
            })
            .catch(error => {
                alert("Please make sure you have made a selection for each field.")
                console.log(error.response)
            })

    }

    const changeShare = async (e) => {
        e.preventDefault();
        axios.put(`${process.env.REACT_APP_API}/adminUsers/${adminData.id}/`, {
            username: adminData.username,
            first_name: adminData.first_name,
            last_name: adminData.last_name,
            email: adminData.email,
            phone: adminData.phone,
            location: adminData.location,
            state: adminData.state,
            country: adminData.country,
            continent: adminData.continent,
            type: adminData.type,
            share_score: e.target.value
        })
            .then(res => {
                window.location.reload()
            })
            .catch(error => {
                alert("Please make sure you have made a selection for each field.")
                console.log(error.response)
            })
    }
    // const changeShare = async (e) => {
    //     e.preventDefault();
    //     console.log(e.target.value)
    // }

    return (
        <div>
            {loading && <div className='spinner'>
                <Spinner animation="border" role="status" size='lg'>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>}
            {!loading && (<>
                <h1>Workout List</h1>
                <h5>Share your scores?</h5>
                <div className="shareDiv">
                    <Form>
                        <Form.Select size="sm" type="number" defaultValue={shared} onChange={(e) => changeShare(e)} >
                            <option hidden>{shared}</option>
                            <option value={"No"}>No</option>
                            <option value={"Yes"}>Yes</option>
                        </ Form.Select>
                    </Form>
                </div>
                {shareText}
                <br></br>
                <br></br>
                <form onSubmit={(e) => submitPost(e)}>
                    <Table responsive striped bordered hover size="sm" style={{ width: "95%", margin: "auto" }}>
                        <thead>
                            <tr>
                                <th>Station</th>
                                <th>Description</th>
                                <th>Repeat</th>
                                <th>Start Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {/* <Form.Control size="sm" type="text" onChange={(e) => setRound(e.target.value)} required /> */}
                                    {activeStation}
                                    <Form.Text >
                                    </Form.Text>
                                </td>
                                <td>
                                    {/* <Form.Control size="sm" type="text" onChange={(e) => setTitle(e.target.value)} required /> */}
                                    {activeTitle}
                                    <Form.Text >
                                    </Form.Text>
                                </td>
                                <td>
                                    {activeRepeat}
                                </td>
                                <td>
                                    {/* <Form.Control size="sm" type="date" onChange={(e) => setDate(e.target.value)} required /> */}
                                    {activeDate}
                                </td>
                                <td>
                                    {/* <Button size="sm" type="submit" disabled >Add</Button> */}
                                    {activeButton}
                                </td>
                            </tr>
                            {all1Form}
                            {all2Form}
                            {all3Form}
                            {all4Form}
                            {all5Form}
                            {all6Form}
                            {all7Form}
                            {all8Form}
                            {all9Form}
                        </tbody>
                    </Table>
                </form>
                <br></br>
                <Table responsive striped bordered hover size="sm" style={{ width: "95%", margin: "auto" }}>
                    <thead>
                        <tr>
                            <th>Station</th>
                            <th>Description</th>
                            <th>Start Date</th>
                            <th>Workout Log</th>
                            {/* <th>Edit</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {chows}
                    </tbody>
                </Table>
            </>)
            }
        </div >
    )
}

export default WorkoutList