import React from 'react';
import Form from 'react-bootstrap/Form'
import react, { useEffect, useState } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom'
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';



function Dashboard() {
    let { user } = useContext(AuthContext)
    let id = user.user_id
    let [loading, setLoading] = useState(true)
    let [adminData, setAdminData] = useState({})
    let [todayChow, setChowToday] = useState({})
    let [lastChow, setLastChow] = useState({})
    let [unChow, setUnChow] = useState({})
    let [chow, setChow] = useState({})
    let [userList, setUserList] = useState({})
    let [allRound, setAllRound] = useState("")
    let [member, setMember] = useState("Select Member")
    let [score, setScore] = useState(0)
    let [all1Score, setAll1Score] = useState(0)
    let [all2Score, setAll2Score] = useState(0)
    let [all3Score, setAll3Score] = useState(0)
    let [all4Score, setAll4Score] = useState(0)
    let [all5Score, setAll5Score] = useState(0)
    let [all6Score, setAll6Score] = useState(0)
    let [all7Score, setAll7Score] = useState(0)
    let [all8Score, setAll8Score] = useState(0)
    let [all9Score, setAll9Score] = useState(0)
    let [all1Title, setAll1Title] = useState("")
    let [all2Title, setAll2Title] = useState("")
    let [all3Title, setAll3Title] = useState("")
    let [all4Title, setAll4Title] = useState("")
    let [all5Title, setAll5Title] = useState("")
    let [all6Title, setAll6Title] = useState("")
    let [all7Title, setAll7Title] = useState("")
    let [all8Title, setAll8Title] = useState("")
    let [all9Title, setAll9Title] = useState("")
    let state = adminData.state
    let location = adminData.location
    let [userID, setUserID] = useState("")
    let average = 0
    let total = 0
    let firstName = null
    let firstScore = null
    let secondName = null
    let secondScore = null
    let thirdName = null
    let thirdScore = null
    let fourthName = null
    let fourthScore = null
    let fifthName = null
    let fifthScore = null
    let firstNameLast = null
    let secondNameLast = null
    let thirdNameLast = null
    let fourthNameLast = null
    let fifthNameLast = null
    let chowRound = null
    let chowTitle = null
    let chowID = null
    let chowDate = null
    let standardScore
    let userThisWeek
    let station1
    let station2
    let station3
    let station4
    let station5
    let station6
    let station7
    let station8
    let station9
    let allScoreBool = false
    let all9ScoreBool = false
    let displayAllTotal
    let searchScore = []
    let sortSearchScore = []
    const [searchUser, setSearchUser] = useState("")
    const [searchUserScore, setSearchUserScore] = useState("")
    const [searchUserName, setSearchUserName] = useState("")
    const [toggleTiles, setToggleTiles] = useState(false)
    const [showTiles, setShowTiles] = useState("Show Score Tiles")
    let tileDisplay = <><Link className='descLink' onClick={() => changeTile() & setToggleTiles(!toggleTiles)}><h5>Click to {showTiles}</h5></Link></>
    let lookupText = <option value={null} hidden>Lookup Score</option>
    function changeTile() {
        if (toggleTiles == true) {
            setShowTiles("Show Score Tiles")
        }
        if (toggleTiles == false) {
            setShowTiles("Hide Score Tiles")
        }
    }

    let scoreLookupList

    useEffect(() => {
        const fetchData = async () => {
            const { data: adminData } = await axios.get(`${process.env.REACT_APP_API}/adminUsers/${id}/`)
            setAdminData(adminData)
            const { data: orderResponse } = await axios.get(`${process.env.REACT_APP_API}/todays_chow/${adminData.state}/${adminData.location}/`)
            setChowToday(orderResponse)
            const { data: unOrderResponse } = await axios.get(`${process.env.REACT_APP_API}/unordered_chow/${adminData.state}/${adminData.location}/`)
            setUnChow(unOrderResponse)
            const { data: chowResponse } = await axios.get(`${process.env.REACT_APP_API}/this_week_chow/`)
            setChow(chowResponse)
            const { data: userResponse } = await axios.get(`${process.env.REACT_APP_API}/location_users/${adminData.state}/${adminData.location}/`)
            setUserList(userResponse)
            const { data: lastResponse } = await axios.get(`${process.env.REACT_APP_API}/last_chow/${adminData.state}/${adminData.location}/`)
            setLastChow(lastResponse)
            setLoading(false)
        }
        fetchData()
    }, [])
    if (adminData === null) {
        setLoading(true)
    }
    let currentUsers = Array.from(userList)
    let users = ''
    let chowThisWeek = Array.from(todayChow)
    let unorderedChow = Array.from(unChow)
    let lastOrderedChow = Array.from(lastChow)
    let chowList = []
    let totalInputThisWeek = chowThisWeek.length
    let chowArray = Array.from(chow)
    let uniqueChow = []
    let lastUnique = []
    let lastLink
    let thisLink
    if (lastOrderedChow.length > 0) {
        lastUnique = [... new Set(lastOrderedChow.map((object, index) => object.user_id))]
        lastLink = `/WorkoutDetail/${lastOrderedChow[0].chow_id}/${adminData.state}/${adminData.location}`
        for (let i = 0; i < lastOrderedChow.length; i++) {
            if (lastOrderedChow[i].user_id === lastUnique[0]) {
                firstNameLast = lastOrderedChow[i].user
            }
        }
        for (let i = 0; i < lastOrderedChow.length; i++) {
            if (lastOrderedChow[i].user_id === lastUnique[1]) {
                secondNameLast = lastOrderedChow[i].user
            }
        }
        for (let i = 0; i < lastOrderedChow.length; i++) {
            if (lastOrderedChow[i].user_id === lastUnique[2]) {
                thirdNameLast = lastOrderedChow[i].user
            }
        }
        for (let i = 0; i < lastOrderedChow.length; i++) {
            if (lastOrderedChow[i].user_id === lastUnique[3]) {
                fourthNameLast = lastOrderedChow[i].user
            }
        }
        for (let i = 0; i < lastOrderedChow.length; i++) {
            if (lastOrderedChow[i].user_id === lastUnique[4]) {
                fifthNameLast = lastOrderedChow[i].user
            }
        }
    }

    for (let i = 0; i < totalInputThisWeek; i++) {
        total += chowThisWeek[i].score
    }
    let testUniq
    if (loading === false) {
        if (chow.length > 0) {
            thisLink = `/WorkoutDetail/${chow[0].id}/${adminData.state}/${adminData.location}`
            uniqueChow = [... new Set(chowThisWeek.map((object, index) => object.user_id))]
            all1Title = chow[0].all_1_title
            all2Title = chow[0].all_2_title
            all3Title = chow[0].all_3_title
            all4Title = chow[0].all_4_title
            all5Title = chow[0].all_5_title
            all6Title = chow[0].all_6_title
            all7Title = chow[0].all_7_title
            all8Title = chow[0].all_8_title
            all9Title = chow[0].all_9_title
            // console.log(all1Title)
        }
        if (chowThisWeek.length === 0) {
            // firstName = 'TBD'
            // secondName = 'TBD'
            // thirdName = 'TBD'
            // fourthName = 'TBD'
            // fifthName = 'TBD'
        } else if (chowThisWeek.length === 1) {
            for (let i = 0; i < chowThisWeek.length; i++) {
                if (chowThisWeek[i].user_id === uniqueChow[0]) {
                    firstName = chowThisWeek[i].user
                    firstScore = chowThisWeek[i].score
                    i = chowThisWeek - 1
                }
            }
        } else if (chowThisWeek.length === 2) {
            for (let i = 0; i < chowThisWeek.length; i++) {
                if (chowThisWeek[i].user_id === uniqueChow[0]) {
                    firstName = chowThisWeek[i].user
                    firstScore = chowThisWeek[i].score
                    i = chowThisWeek - 1
                }
            }
            for (let i = 0; i < chowThisWeek.length; i++) {
                if (chowThisWeek[i].user_id === uniqueChow[1]) {
                    secondName = chowThisWeek[i].user
                    secondScore = chowThisWeek[i].score
                    i = chowThisWeek - 1
                }
            }
        } else if (chowThisWeek.length === 3) {
            for (let i = 0; i < chowThisWeek.length; i++) {
                if (chowThisWeek[i].user_id === uniqueChow[0]) {
                    firstName = chowThisWeek[i].user
                    firstScore = chowThisWeek[i].score
                    i = chowThisWeek - 1
                }
            }
            for (let i = 0; i < chowThisWeek.length; i++) {
                if (chowThisWeek[i].user_id === uniqueChow[1]) {
                    secondName = chowThisWeek[i].user
                    secondScore = chowThisWeek[i].score
                    i = chowThisWeek - 1
                }
            }
            for (let i = 0; i < chowThisWeek.length; i++) {
                if (chowThisWeek[i].user_id === uniqueChow[2]) {
                    thirdName = chowThisWeek[i].user
                    thirdScore = chowThisWeek[i].score
                    i = chowThisWeek - 1
                }
            }
        } else if (chowThisWeek.length === 4) {
            for (let i = 0; i < chowThisWeek.length; i++) {
                if (chowThisWeek[i].user_id === uniqueChow[0]) {
                    firstName = chowThisWeek[i].user
                    firstScore = chowThisWeek[i].score
                    i = chowThisWeek - 1
                }
            }
            for (let i = 0; i < chowThisWeek.length; i++) {
                if (chowThisWeek[i].user_id === uniqueChow[1]) {
                    secondName = chowThisWeek[i].user
                    secondScore = chowThisWeek[i].score
                    i = chowThisWeek - 1
                }
            }
            for (let i = 0; i < chowThisWeek.length; i++) {
                if (chowThisWeek[i].user_id === uniqueChow[2]) {
                    thirdName = chowThisWeek[i].user
                    thirdScore = chowThisWeek[i].score
                    i = chowThisWeek - 1
                }
            }
            for (let i = 0; i < chowThisWeek.length; i++) {
                if (chowThisWeek[i].user_id === uniqueChow[3]) {
                    fourthName = chowThisWeek[i].user
                    fourthScore = chowThisWeek[i].score
                    i = chowThisWeek - 1
                }
            }
        } else if (chowThisWeek.length >= 5) {
            for (let i = 0; i < chowThisWeek.length; i++) {
                if (chowThisWeek[i].user_id === uniqueChow[0]) {
                    firstName = chowThisWeek[i].user
                    firstScore = chowThisWeek[i].score
                    i = chowThisWeek - 1
                }
            }
            for (let i = 0; i < chowThisWeek.length; i++) {
                if (chowThisWeek[i].user_id === uniqueChow[1]) {
                    secondName = chowThisWeek[i].user
                    secondScore = chowThisWeek[i].score
                    i = chowThisWeek - 1
                }
            }
            for (let i = 0; i < chowThisWeek.length; i++) {
                if (chowThisWeek[i].user_id === uniqueChow[2]) {
                    thirdName = chowThisWeek[i].user
                    thirdScore = chowThisWeek[i].score
                    i = chowThisWeek - 1
                }
            }
            for (let i = 0; i < chowThisWeek.length; i++) {
                if (chowThisWeek[i].user_id === uniqueChow[3]) {
                    fourthName = chowThisWeek[i].user
                    fourthScore = chowThisWeek[i].score
                    i = chowThisWeek - 1
                }
            }
            for (let i = 0; i < chowThisWeek.length; i++) {
                if (chowThisWeek[i].user_id === uniqueChow[4]) {
                    fifthName = chowThisWeek[i].user
                    fifthScore = chowThisWeek[i].score
                    i = chowThisWeek - 1
                }
            }
        }
        if (chowArray.length === 0) {
            chowRound = 'Coming Soon'
            chowTitle = "Coming Soon"
            chowID = "Coming Soon"
            chowDate = "Coming Soon"
        } else {
            chowRound = chow[0].round
            // chowRound = "All"
            chowTitle = chow[0].title
            chowID = chow[0].id
            chowDate = chow[0].start
            if (chowRound === 'All') {
                if (userList) {
                    //add a form type to change score method/1 score vs all score? all fields at once? find this week chow if it exists?
                    for (let i = 0; i < chowThisWeek.length; i++) {

                        if (chowThisWeek[i].user_id === userID && (chowThisWeek[i].all_1_score == null || chowThisWeek[i].all_2_score == null
                            || chowThisWeek[i].all_3_score == null || chowThisWeek[i].all_4_score == null || chowThisWeek[i].all_5_score == null || chowThisWeek[i].all_6_score == null || chowThisWeek[i].all_7_score == null
                            || chowThisWeek[i].all_8_score == null || chowThisWeek[i].all_9_score == null)) {

                            allScoreBool = true
                            // setScore(chowThisWeek.all_1_score + chowThisWeek.all_2_score + chowThisWeek.all_3_score + chowThisWeek.all_4_score + chowThisWeek.all_5_score +
                            //     chowThisWeek.all_6_score + chowThisWeek.all_7_score + chowThisWeek.all_8_score + chowThisWeek.all_9_score)
                            if (chowThisWeek[i].all_1_score != null) {
                                // score = score + parseFloat(chowThisWeek[i].all_1_score)
                                all1Score = chowThisWeek[i].all_1_score
                            }
                            if (chowThisWeek[i].all_2_score != null) {
                                // score = score + parseFloat(chowThisWeek[i].all_2_score)
                                all2Score = chowThisWeek[i].all_2_score
                            }
                            if (chowThisWeek[i].all_3_score != null) {
                                // score = score + parseFloat(chowThisWeek[i].all_3_score)
                                all3Score = chowThisWeek[i].all_3_score
                            }
                            if (chowThisWeek[i].all_4_score != null) {
                                // score = score + parseFloat(chowThisWeek[i].all_4_score)
                                all4Score = chowThisWeek[i].all_4_score
                            }
                            if (chowThisWeek[i].all_5_score != null) {
                                // score = score + parseFloat(chowThisWeek[i].all_5_score)
                                all5Score = chowThisWeek[i].all_5_score
                            }
                            if (chowThisWeek[i].all_6_score != null) {
                                // score = score + parseFloat(chowThisWeek[i].all_6_score)
                                all6Score = chowThisWeek[i].all_6_score
                            }
                            if (chowThisWeek[i].all_7_score != null) {
                                // score = score + parseFloat(chowThisWeek[i].all_7_score)
                                all7Score = chowThisWeek[i].all_7_score
                            }
                            if (chowThisWeek[i].all_8_score != null) {
                                // score = score + parseFloat(chowThisWeek[i].all_8_score)
                                all8Score = chowThisWeek[i].all_8_score
                            }
                            if (chowThisWeek[i].all_9_score != null) {
                                // score = score + parseFloat(chowThisWeek[i].all_9_score)
                                all9Score = chowThisWeek[i].all_9_score
                            }
                            userThisWeek = chowThisWeek[i]
                            i = chowThisWeek.length - 1
                        } else if (chowThisWeek[i].all_1_score && chowThisWeek[i].all_2_score
                            && chowThisWeek[i].all_3_score && chowThisWeek[i].all_4_score && chowThisWeek[i].all_5_score && chowThisWeek[i].all_6_score && chowThisWeek[i].all_7_score
                            && chowThisWeek[i].all_8_score && chowThisWeek[i].all_9_score && chowThisWeek[i].score) {
                            all9ScoreBool = true
                            allScoreBool = false
                            // i = chowThisWeek.length - 1
                        } else {
                            allScoreBool = false
                        }
                    }

                    station1 = <div className='allForm'>
                        <div>
                            <h5>Station 1:</h5>
                        </div>
                        <div className='allFormScore'>
                            <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={null} onChange={(e) => setAll1Score(e.target.value)} />
                        </div>
                    </div>
                    station2 = <div className='allForm'>
                        <div>
                            <h5>Station 2:</h5>
                        </div>
                        <div className='allFormScore'>
                            <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={null} onChange={(e) => setAll2Score(e.target.value)} />
                        </div>
                    </div>
                    station3 = <div className='allForm'>
                        <div>
                            <h5>Station 3:</h5>
                        </div>
                        <div className='allFormScore'>
                            <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={null} onChange={(e) => setAll3Score(e.target.value)} />
                        </div>
                    </div>
                    station4 = <div className='allForm'>
                        <div>
                            <h5>Station 4:</h5>
                        </div>
                        <div className='allFormScore'>
                            <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={null} onChange={(e) => setAll4Score(e.target.value)} />
                        </div>
                    </div>
                    station5 = <div className='allForm'>
                        <div>
                            <h5>Station 5:</h5>
                        </div>
                        <div className='allFormScore'>
                            <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={null} onChange={(e) => setAll5Score(e.target.value)} />
                        </div>
                    </div>
                    station6 = <div className='allForm'>
                        <div>
                            <h5>Station 6:</h5>
                        </div>
                        <div className='allFormScore'>
                            <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={null} onChange={(e) => setAll6Score(e.target.value)} />
                        </div>
                    </div>
                    station7 = <div className='allForm'>
                        <div>
                            <h5>Station 7:</h5>
                        </div>
                        <div className='allFormScore'>
                            <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={null} onChange={(e) => setAll7Score(e.target.value)} />
                        </div>
                    </div>
                    station8 = <div className='allForm'>
                        <div>
                            <h5>Station 8:</h5>
                        </div>
                        <div className='allFormScore'>
                            <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={null} onChange={(e) => setAll8Score(e.target.value)} />
                        </div>
                    </div>
                    station9 = <div className='allForm'>
                        <div>
                            <h5>Station 9:</h5>
                        </div>
                        <div className='allFormScore'>
                            <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={null} onChange={(e) => setAll9Score(e.target.value)} />
                        </div>
                    </div>
                    // displayAllTotal = <div className='allForm'>
                    //     <div>
                    //         <h5>Total : {score}</h5>
                    //     </div>
                    // </div>
                    // score = parseFloat(all1Score) + parseFloat(all2Score) + parseFloat(all3Score) + parseFloat(all4Score) + parseFloat(all5Score) + parseFloat(all6Score) + parseFloat(all7Score) + parseFloat(all8Score) + parseFloat(all9Score)
                    if (all1Score == 0) {
                        all1Score = null
                    } else {
                        score = score + parseFloat(all1Score)
                    }
                    if (all2Score == 0) {
                        all2Score = null
                    } else {
                        score = score + parseFloat(all2Score)
                    }
                    if (all3Score == 0) {
                        all3Score = null
                    } else {
                        score = score + parseFloat(all3Score)
                    }
                    if (all4Score == 0) {
                        all4Score = null
                    } else {
                        score = score + parseFloat(all4Score)
                    }
                    if (all5Score == 0) {
                        all5Score = null
                    } else {
                        score = score + parseFloat(all5Score)
                    }
                    if (all6Score == 0) {
                        all6Score = null
                    } else {
                        score = score + parseFloat(all6Score)
                    }
                    if (all7Score == 0) {
                        all7Score = null
                    } else {
                        score = score + parseFloat(all7Score)
                    }
                    if (all8Score == 0) {
                        all8Score = null
                    } else {
                        score = score + parseFloat(all8Score)
                    }
                    if (all9Score == 0) {
                        all9Score = null
                    } else {
                        score = score + parseFloat(all9Score)
                    }

                    // console.log(score)
                    displayAllTotal = <div className='allForm'>
                        <div>
                            <h5>Total : {score}</h5>
                        </div>
                    </div>

                    if (userThisWeek && userThisWeek.all_1_score != null) {
                        station1 = <div className='allForm'>
                            <div>
                                <h5>Station 1:</h5>
                            </div>
                            <div className='allFormScore'>
                                <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={userThisWeek.all_1_score} disabled />
                            </div>
                        </div>
                    }
                    if (userThisWeek && userThisWeek.all_2_score != null) {
                        station2 = <div className='allForm'>
                            <div>
                                <h5>Station 2:</h5>
                            </div>
                            <div className='allFormScore'>
                                <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={userThisWeek.all_2_score} disabled />
                            </div>
                        </div>
                    }
                    if (userThisWeek && userThisWeek.all_3_score != null) {
                        station3 = <div className='allForm'>
                            <div>
                                <h5>Station 3:</h5>
                            </div>
                            <div className='allFormScore'>
                                <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={userThisWeek.all_3_score} disabled />
                            </div>
                        </div>
                    }
                    if (userThisWeek && userThisWeek.all_4_score != null) {
                        station4 = <div className='allForm'>
                            <div>
                                <h5>Station 4:</h5>
                            </div>
                            <div className='allFormScore'>
                                <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={userThisWeek.all_4_score} disabled />
                            </div>
                        </div>
                    }
                    if (userThisWeek && userThisWeek.all_5_score != null) {
                        station5 = <div className='allForm'>
                            <div>
                                <h5>Station 5:</h5>
                            </div>
                            <div className='allFormScore'>
                                <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={userThisWeek.all_5_score} disabled />
                            </div>
                        </div>
                    }
                    if (userThisWeek && userThisWeek.all_6_score != null) {
                        station6 = <div className='allForm'>
                            <div>
                                <h5>Station 6:</h5>
                            </div>
                            <div className='allFormScore'>
                                <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={userThisWeek.all_6_score} disabled />
                            </div>
                        </div>
                    }
                    if (userThisWeek && userThisWeek.all_7_score != null) {
                        station7 = <div className='allForm'>
                            <div>
                                <h5>Station 7:</h5>
                            </div>
                            <div className='allFormScore'>
                                <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={userThisWeek.all_7_score} disabled />
                            </div>
                        </div>
                    }
                    if (userThisWeek && userThisWeek.all_8_score != null) {
                        station8 = <div className='allForm'>
                            <div>
                                <h5>Station 8:</h5>
                            </div>
                            <div className='allFormScore'>
                                <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={userThisWeek.all_8_score} disabled />
                            </div>
                        </div>
                    }
                    if (userThisWeek && userThisWeek.all_9_score != null) {
                        station9 = <div className='allForm'>
                            <div>
                                <h5>Station 9:</h5>
                            </div>
                            <div className='allFormScore'>
                                <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={userThisWeek.all_9_score} disabled />
                            </div>
                        </div>
                    } else {

                    }
                } else {
                    // station1 = <div className='allForm'>
                    //     <div>
                    //         <h5>Station 1:</h5>
                    //     </div>
                    //     <div className='allFormScore'>
                    //         <Form.Control className='allFormScoreInput' size="sm" type="number" defaultValue={null} onChange={(e) => setScore(e.target.value)} required />
                    //     </div>
                    // </div>
                }

            } else {
                standardScore = <div>
                    <div className='formSpacing'>
                        <Form.Control size="sm" type="number" onChange={(e) => setScore(e.target.value)} required />
                    </div>
                </div>
            }

        }
        // adminName = User.username
    }
    let userScoreList = []
    let displayScoreList
    if (searchUser != '') {
        for (let i = 0; i < unorderedChow.length; i++) {
            if (unorderedChow[i].user_id == searchUser) {
                userScoreList.push(unorderedChow[i].score)
                // setSearchUserScore(unorderedChow[i].score)
            }
        }
        displayScoreList = userScoreList.map((user, index) => {
            return <>
                <h5>{user}</h5>
            </>
        })

    }
    if (unorderedChow && chowRound === 'All') {
        chowList = unorderedChow.map((chow, index) => {
            return <div key={chow.id}>
                <Link to={`/${chow.user_id}/MemberPage`} className="scoreLink" >
                    <div className='score' >
                        <h5 className='scoreH5'>{chow.user} - {chow.score}</h5>
                        <h6>1: {chow.all_1_score}</h6>
                        <h6>2: {chow.all_2_score}</h6>
                        <h6>3: {chow.all_3_score}</h6>
                        <h6>4: {chow.all_4_score}</h6>
                        <h6>5: {chow.all_5_score}</h6>
                        <h6>6: {chow.all_6_score}</h6>
                        <h6>7: {chow.all_7_score}</h6>
                        <h6>8: {chow.all_8_score}</h6>
                        <h6>9: {chow.all_9_score}</h6>
                    </div>
                </Link>
            </div >
        })
        for (let i = 0; i < currentUsers.length; i++) {
            for (let j = 0; j < unorderedChow.length; j++) {
                if (currentUsers[i].id == unorderedChow[j].user_id) {
                    sortSearchScore.push({ user: currentUsers[i].nickname, userID: unorderedChow[j].user_id })
                }
            }
        }
        searchScore = sortSearchScore.map((user, index) => {
            return <>
                <option key={index} value={user.userID}>{user.user}</option>
            </>
        })
    } else {
        chowList = unorderedChow.map((chow, index) => {
            return <div key={chow.id}>
                <Link to={`/${chow.user_id}/MemberPage`} className="scoreLink" >
                    <div className='score' >
                        <h5 className='scoreH5'>{chow.user} - {chow.score}</h5>
                    </div>
                </Link>
            </div >
        })
        for (let i = 0; i < currentUsers.length; i++) {
            for (let j = 0; j < unorderedChow.length; j++) {
                if (currentUsers[i].id == unorderedChow[j].user_id) {
                    sortSearchScore.push({ user: currentUsers[i].nickname, userID: unorderedChow[j].user_id })
                }
            }
        }
        // console.log(sortSearchScore)
        searchScore = sortSearchScore.map((user, index) => {
            return <>
                <option key={index} value={user.userID}>{user.user}</option>
            </>
        })
        for (let i = 0; i < unorderedChow.length; i++) {
            if (unorderedChow[i].user_id == user) {
                // userScoreList.push(unorderedChow[i].score)
                setSearchUserScore(unorderedChow[i].score)
            }
        }
    }

    average = total / totalInputThisWeek
    let cleanAverage = Math.round(average)

    if (searchUserScore != '') {

    }

    // console.log(userScoreList)
    // if (userScoreList) {
    //     displayScoreList = userScoreList.map((user, index) => {
    //         console.log(user)
    //         return <>
    //             <h5>{user}</h5>
    //         </>
    //     })
    // }

    if (currentUsers) {
        users = currentUsers.map((user, index) => {
            return <>
                <option value={index}>{user.nickname}</option>
            </>
        })
    }
    const submitPost = async (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API}/all_chow/`, {
            chow_id: chowID,
            chow: chowTitle,
            user: member,
            start: chowDate,
            score: score,
            state: state,
            location: location,
            country: adminData.country,
            continent: adminData.continent,
            user_id: userID,
            all_1_score: all1Score,
            all_2_score: all2Score,
            all_3_score: all3Score,
            all_4_score: all4Score,
            all_5_score: all5Score,
            all_6_score: all6Score,
            all_7_score: all7Score,
            all_8_score: all8Score,
            all_9_score: all9Score,
            all_1_title: all1Title,
            all_2_title: all2Title,
            all_3_title: all3Title,
            all_4_title: all4Title,
            all_5_title: all5Title,
            all_6_title: all6Title,
            all_7_title: all7Title,
            all_8_title: all8Title,
            all_9_title: all9Title,
            all_1_station: 1,
            all_2_station: 2,
            all_3_station: 3,
            all_4_station: 4,
            all_5_station: 5,
            all_6_station: 6,
            all_7_station: 7,
            all_8_station: 8,
            all_9_station: 9

        })
            .then(res => {
                // console.log(res.data)
                window.location.reload()
            })
            .catch(error => {
                console.log(error.response)
            })

    }
    // console.log(userThisWeek)
    //create put update to chow id...
    const updatePost = async (e) => {
        e.preventDefault();
        axios.put(`${process.env.REACT_APP_API}/all_chow/update/${userThisWeek.id}`, {
            chow_id: chowID,
            chow: chowTitle,
            user: member,
            start: chowDate,
            score: score,
            state: state,
            location: location,
            country: adminData.country,
            continent: adminData.continent,
            user_id: userID,
            score: score,
            all_1_score: all1Score,
            all_2_score: all2Score,
            all_3_score: all3Score,
            all_4_score: all4Score,
            all_5_score: all5Score,
            all_6_score: all6Score,
            all_7_score: all7Score,
            all_8_score: all8Score,
            all_9_score: all9Score,
            all_1_title: all1Title,
            all_2_title: all2Title,
            all_3_title: all3Title,
            all_4_title: all4Title,
            all_5_title: all5Title,
            all_6_title: all6Title,
            all_7_title: all7Title,
            all_8_title: all8Title,
            all_9_title: all9Title,
            all_1_station: 1,
            all_2_station: 2,
            all_3_station: 3,
            all_4_station: 4,
            all_5_station: 5,
            all_6_station: 6,
            all_7_station: 7,
            all_8_station: 8,
            all_9_station: 9
        })
            .then(res => {
                window.location.reload()
            })
            .catch(error => {
                console.log(error.response)
            })

    }

    return (
        <div className='dashboard'>
            {loading && <div className='spinner'>
                <Spinner animation="border" role="status" size='lg'>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>}
            {!loading && (<>
                <div className='dashHeader'>
                    <Link to={lastLink} className='headLink'>
                        <div className='scoreTitleContainer'>
                            <div className='title'>
                                <h3>Last Week's Top 5</h3>
                            </div>
                            <div className='topScores'>
                                <div className='first'>
                                    <h4>1st: {firstNameLast}</h4>
                                </div>
                                <div className='second'>
                                    <h4>2nd: {secondNameLast}</h4>
                                </div>
                                <div className='third'>
                                    <h4>3rd: {thirdNameLast}</h4>
                                </div>
                                <div className='fourth'>
                                    <h4>4th: {fourthNameLast}</h4>
                                </div>
                                <div className='fifth'>
                                    <h4>5th: {fifthNameLast}</h4>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={thisLink} className="headLink">
                        <div className='scoreTitleContainer'>
                            <div className='title'>
                                <h5>Station: {chowRound}</h5>
                                <h2>{chowTitle}</h2>
                            </div>
                            <div className='topScores'>
                                <div className='first'>
                                    <h3>1st: {firstName} - {firstScore}</h3>
                                </div>
                                <div className='second'>
                                    <h3>2nd: {secondName} - {secondScore}</h3>
                                </div>
                                <div className='third'>
                                    <h3>3rd: {thirdName} - {thirdScore}</h3>
                                </div>
                                <div className='fourth'>
                                    <h3>4th: {fourthName} - {fourthScore}</h3>
                                </div>
                                <div className='fifth'>
                                    <h3>5th: {fifthName} - {fifthScore}</h3>
                                </div>
                            </div>
                            <br></br>
                        </div>
                    </Link>
                    <div className='formThird'>
                        <h2>Add Score</h2>
                        <div className='form'>
                            {allScoreBool && (<> <form onSubmit={(e) => updatePost(e)}>
                                <div className='formSpacing'>
                                    <Form.Select required size="sm" defaultValue={member} onChange={(e) => setUserID(currentUsers[e.target.value].id) & setMember(currentUsers[e.target.value].nickname)} >
                                        <option value={user.index} hidden>{member}</option>
                                        {users}
                                    </Form.Select>
                                </div>
                                {station1}
                                {station2}
                                {station3}
                                {station4}
                                {station5}
                                {station6}
                                {station7}
                                {station8}
                                {station9}
                                {displayAllTotal}
                                <div className='formSpacing'>
                                    <Button size="sm" type="submit" variant='dark' >Update</Button>
                                </div>
                            </form>
                            </>)}
                            {!allScoreBool && (<> <form onSubmit={(e) => submitPost(e)}>
                                <div className='formSpacing'>
                                    <Form.Select required size="sm" onChange={(e) => setUserID(currentUsers[e.target.value].id) & setMember(currentUsers[e.target.value].nickname)} >
                                        <option value="" hidden>{member}</option>
                                        {users}
                                    </Form.Select>
                                </div>
                                {station1}
                                {station2}
                                {station3}
                                {station4}
                                {station5}
                                {station6}
                                {station7}
                                {station8}
                                {station9}
                                {displayAllTotal}
                                {standardScore}
                                <div className='formSpacing'>
                                    <Button size="sm" type="submit" variant='dark' >Add</Button>
                                </div>
                            </form></>)}
                        </div>
                    </div>
                </div >
                <br></br>
                <div className='lookupScore'>
                    <Form>
                        <div>
                            <h5>Lookup Score</h5>
                            <div className='formSpacing'>
                                <Form.Select required size="sm" onChange={(e) => setSearchUser(e.target.value)} >
                                    {lookupText}
                                    {searchScore}
                                </Form.Select>
                            </div>
                        </div>
                        {/* <h5>{searchUserScore}</h5> */}
                        {displayScoreList}
                        <div className='formSpacing'>
                            <Button size="sm" type='submit' variant='dark' >Clear</Button>
                        </div>
                    </Form>
                </div>
                <br></br>
                <br></br>
                <div>
                    <h5>Total Attempts: {totalInputThisWeek} - Average Score: {cleanAverage}</h5>
                </div>
                {tileDisplay}
                {toggleTiles && <div className='scoreList'>
                    {chowList}
                </div>}
                {!toggleTiles && (<>

                </>)
                }
                <br></br>
                <br></br>
            </>)
            }
        </div >
    )

}

export default Dashboard