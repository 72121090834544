import { useState, useContext } from "react";
import AuthContext from "../context/AuthContext";
import { FloatingLabel, Form, Button } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";


function ResetPassword() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const { user } = useContext(AuthContext)
    const { resetPassword } = useContext(AuthContext);
    const { authTokens } = useContext(AuthContext)
    const { logoutUser } = useContext(AuthContext)
    const history = useNavigate();
    // console.log(authTokens.access)
    let pk = user.user_id
    const submitPost = async (e) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ` + authTokens.access
        e.preventDefault();
        const response = await axios.put(`${process.env.REACT_APP_API}/change_password/${pk}/`, {
            password: newPassword,
            password2: newPassword2,
            old_password: password
        })
            .then(res => {
                //console.log(res);
                logoutUser();
                // window.location = `/login`
            })
            .catch(error => {
                if (newPassword != newPassword2) {
                    alert('New Passwords Do Not Match')
                }
                if (newPassword.length < 8) {
                    alert('Password must have at least 8 characters')
                }
                if (newPassword === newPassword2 && newPassword.length >= 8 && error.response.status === 400) {
                    alert('Please make sure Old Password is correct and that you did not use Old Password as New Password')
                }
                // console.log(error.response.status)
            })
        // if (response.status === 201) {
        //     history("/login");
        // } else {
        //     alert("Something went wrong!");
        // }
        // .then(res => {
        //     console.log(response.status)
        //     logoutUser()
        // })
        // .catch(error => {
        //     console.log(response.status)
        //     console.log(error.response)
        // })

    }

    return (
        <div className="loginBox">
            <form onSubmit={(e) => submitPost(e)}>
                <h2>Reset Password </h2>
                <div className="passWord">
                    <FloatingLabel
                        controlId="password"
                        label="Old Password"
                        className="mb-3"
                    >
                        <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Old Password" required />
                    </FloatingLabel>
                </div>
                <div className="passWord">
                    <FloatingLabel
                        controlId="newPassword"
                        label="New Password *"
                        className="mb-3"
                    >
                        <Form.Control type="password" onChange={(e) => setNewPassword(e.target.value)} placeholder="New Password" />
                    </FloatingLabel>
                </div>
                <div className="passWord">
                    <FloatingLabel
                        controlId="newPassword2"
                        label="Repeat New Password *"
                        className="mb-3"
                    >
                        <Form.Control type="password" onChange={(e) => setNewPassword2(e.target.value)} placeholder="Repeat New Password" />
                    </FloatingLabel>
                </div>
                <div className="loginButton">
                    <Button size="mb-3" type="submit"  >Reset</Button>
                </div>
            </form >
            <br></br>
            <h6>* Must be at least 8 characters</h6>
            <h6>* Must be alphanumeric</h6>
        </div >
    )
}

export default ResetPassword;