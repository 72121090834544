import react, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner';
import { element, object } from "prop-types";
import Table from 'react-bootstrap/Table'
import { Link } from "react-router-dom";

function MemberPage() {
    const { id } = useParams()
    let [loading, setLoading] = useState(true)
    let [woList, setWoList] = useState({})
    let [allUserData, setAllUserData] = useState({})
    let [currentUserData, setCurrentUserData] = useState({})
    let [userWoData, setUserWoData] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const { data: response } = await axios.get(`${process.env.REACT_APP_API}/chow_list/`)
            setWoList(response)
            const { data: userWo } = await axios.get(`${process.env.REACT_APP_API}/all_chow/${id}/`)
            setUserWoData(userWo)
            const { data: currentResponse } = await axios.get(`${process.env.REACT_APP_API}/user_list/${id}/`)
            setCurrentUserData(currentResponse)
            const { data: allUser } = await axios.get(`${process.env.REACT_APP_API}/all_chow/`)
            setAllUserData(allUser)
            setLoading(false);
        }
        fetchData()
    }, [])

    let userArray = Array.from(currentUserData)
    let userArrayLength = userArray.length
    let userNickname = ''
    if (userArrayLength > 0) {
        userNickname = userArray[0].nickname
    }
    let woArray = Array.from(woList)
    let allUserArray = Array.from(allUserData)
    let userWoArray = Array.from(userWoData)
    let userWoIDArray = []
    let uniqueUserWoIDArray = []
    let userObj = {}
    let woListLength = woArray.length
    let allUserLength = allUserArray.length
    let totalUserAttempts = 0
    let totalAttempts = 0
    let totalAttemptHolder = 0
    let memberObject = {}
    let tableData = null
    let total = 0
    let average = 0
    let reversedUnique = []
    let reversedIndex = []
    let position = 0
    let averagePosition = 0
    let cleanPositionAverage
    let averageAttempts
    let denom
    let cleanDenom
    let firstPlace = 0
    let secondPlace = 0
    let thirdPlace = 0
    let topFive = 0
    let bestPosition
    let round
    let tableRow
    let extraRow
    const [showText, setShowText] = useState("Show")
    const [showBool, setShowBool] = useState(false)
    const [userRow, setUserRow] = useState("")
    function toggleText(user) {
        setShowBool(!showBool)
        if (showBool == false) {
            setUserRow(user.id)
            extraRow = <>
                <tr>
                    <td>{user.all_1_title}</td>
                    <td>All - 1</td>
                    <td>{user.all_1_score}</td>
                    <td >
                    </td>
                </tr>
                <tr>
                    <td>{user.all_2_title}</td>
                    <td>All - 2</td>
                    <td>{user.all_2_score}</td>
                    <td >
                    </td>
                </tr>
                <tr>
                    <td>{user.all_3_title}</td>
                    <td>All - 3</td>
                    <td>{user.all_3_score}</td>
                    <td >
                    </td>
                </tr>
                <tr>
                    <td>{user.all_4_title}</td>
                    <td>All - 4</td>
                    <td>{user.all_4_score}</td>
                    <td >
                    </td>
                </tr>
                <tr>
                    <td>{user.all_5_title}</td>
                    <td>All - 5</td>
                    <td>{user.all_5_score}</td>
                    <td >
                    </td>
                </tr>
                <tr>
                    <td>{user.all_6_title}</td>
                    <td>All - 6</td>
                    <td>{user.all_6_score}</td>
                    <td >
                    </td>
                </tr>
                <tr>
                    <td>{user.all_7_title}</td>
                    <td>All - 7</td>
                    <td>{user.all_7_score}</td>
                    <td >
                    </td>
                </tr>
                <tr>
                    <td>{user.all_8_title}</td>
                    <td>All - 8</td>
                    <td>{user.all_8_score}</td>
                    <td >
                    </td>
                </tr>
                <tr>
                    <td>{user.all_9_title}</td>
                    <td>All - 9</td>
                    <td>{user.all_9_score}</td>
                    <td >
                    </td>
                </tr>
                <br></br>
            </>
            setShowText("Hide")
            // setExtraRowBool(extraRow)
        } else {
            setUserRow("")
            extraRow = ""
            setShowText("Show")
        }

    }


    if (userWoArray.length > 0 && userArray.length > 0) {
        for (let i = 0; i < userWoArray.length; i++) {
            for (let j = 0; j < woArray.length; j++) {
                if (woArray[j].id == userWoArray[i].chow_id) {
                    round = woArray[j].round
                }
            }
            userWoIDArray.push({ id: userWoArray[i].chow_id, round: round, all_1_title: userWoArray[i].all_1_title, all_1_score: userWoArray[i].all_1_score, all_2_title: userWoArray[i].all_2_title, all_2_score: userWoArray[i].all_2_score, all_3_title: userWoArray[i].all_3_title, all_3_score: userWoArray[i].all_3_score, all_4_title: userWoArray[i].all_4_title, all_4_score: userWoArray[i].all_4_score, all_5_title: userWoArray[i].all_5_title, all_5_score: userWoArray[i].all_5_score, all_6_title: userWoArray[i].all_6_title, all_6_score: userWoArray[i].all_6_score, all_7_title: userWoArray[i].all_7_title, all_7_score: userWoArray[i].all_7_score, all_8_title: userWoArray[i].all_8_title, all_8_score: userWoArray[i].all_8_score, all_9_title: userWoArray[i].all_9_title, all_9_score: userWoArray[i].all_9_score })
        }
        uniqueUserWoIDArray = [... new Set(userWoIDArray)]
        // reversedUnique = userWoIDArray.reverse()

        userWoIDArray.forEach((element, index) => {
            for (let i = 0; i < allUserArray.length; i++) {
                if (element.id === allUserArray[i].chow_id) {
                    totalAttempts++
                    total = total + allUserArray[i].score
                    if (allUserArray[i].user_id == id && allUserArray[i].id == userWoArray[index].id) {
                        element.title = allUserArray[i].chow
                        element.position = totalAttempts
                        if (element.position == 1) {
                            firstPlace++
                            topFive++
                        } else if (element.position == 2) {
                            secondPlace++
                            topFive++
                        } else if (element.position == 3) {
                            thirdPlace++
                            topFive++
                        } else if (element.position == 4 || element.position == 5) {
                            topFive++
                        }
                    }
                }
                if (i === allUserArray.length - 1) {
                    element.local_attempts = totalAttempts
                    average = total / totalAttempts
                    let cleanAverage = Math.trunc(average)
                    element.local_average = cleanAverage
                    totalAttempts = 0
                    total = 0
                }
            }
        })
        tableData = userWoIDArray.map((user, index) => {
            if (user.round == "All") {
                extraRow = <>
                    <tr>
                        <td>{user.all_1_title}</td>
                        <td>All - 1</td>
                        <td>{user.all_1_score}</td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                    </tr>
                    <tr>
                        <td>{user.all_2_title}</td>
                        <td>All - 2</td>
                        <td>{user.all_2_score}</td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                    </tr>
                    <tr>
                        <td>{user.all_3_title}</td>
                        <td>All - 3</td>
                        <td>{user.all_3_score}</td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                    </tr>
                    <tr>
                        <td>{user.all_4_title}</td>
                        <td>All - 4</td>
                        <td>{user.all_4_score}</td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                    </tr>
                    <tr>
                        <td>{user.all_5_title}</td>
                        <td>All - 5</td>
                        <td>{user.all_5_score}</td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                    </tr>
                    <tr>
                        <td>{user.all_6_title}</td>
                        <td>All - 6</td>
                        <td>{user.all_6_score}</td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                    </tr>
                    <tr>
                        <td>{user.all_7_title}</td>
                        <td>All - 7</td>
                        <td>{user.all_7_score}</td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                    </tr>
                    <tr>
                        <td>{user.all_8_title}</td>
                        <td>All - 8</td>
                        <td>{user.all_8_score}</td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                    </tr>
                    <tr>
                        <td>{user.all_9_title}</td>
                        <td>All - 9</td>
                        <td>{user.all_9_score}</td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                    </tr>
                    <br></br>
                </>
                if (userRow != user.id) {
                    extraRow = ""
                }
                tableRow = <>
                    <tr>
                        <td>
                            <Link to={`/WorkoutDetail/${user.id}/${userArray[0].state}/${userArray[0].location}`} className="descLink">
                                {userWoArray[index].chow}
                            </Link>
                        </td>
                        <td>
                            <Link onClick={() => toggleText(user)} className="descLink">
                                {user.round} (Click to {showText})
                            </Link>
                        </td>
                        <td>{userWoArray[index].score}</td>
                        <td>{user.local_average}</td>
                        <td>{user.position}</td>
                        <td>{user.local_attempts}</td>
                    </tr>
                    {extraRow}
                </>
            } else {
                tableRow =
                    <tr>
                        <td>
                            <Link to={`/WorkoutDetail/${user.id}/${userArray[0].state}/${userArray[0].location}`} className="descLink">
                                {userWoArray[index].chow}
                            </Link>
                        </td>
                        <td>{user.round}</td>
                        <td>{userWoArray[index].score}</td>
                        <td>{user.local_average}</td>
                        <td>{user.position}</td>
                        <td>{user.local_attempts}</td>
                    </tr>
            }
            return <>
                {tableRow}
            </>
        })
        // return <>
        //     <tr>
        //         <td>
        //             <Link to={`/WorkoutDetail/${element.id}/${userArray[0].state}/${userArray[0].location}`} className="descLink">
        //                 {userWoArray[index].chow}
        //             </Link>
        //         </td>
        //         <td>{userWoArray[index].score}</td>
        //         <td>{element.local_average}</td>
        //         <td>{element.position}</td>
        //         <td>{element.local_attempts}</td>
        //     </tr>
        // </>

        userWoIDArray.forEach((element) => {
            position = position + element.position
            totalAttempts = totalAttempts + element.local_attempts
            averageAttempts = totalAttempts / userWoIDArray.length
            averagePosition = position / userWoIDArray.length
            cleanPositionAverage = Math.round(averagePosition)
            cleanDenom = Math.round(averageAttempts)
        })

    }

    return (
        <div>
            {loading && <div className='spinner'>
                <Spinner animation="border" role="status" size='lg'>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>}
            {!loading && (<>
                <div className="MemberHeader">
                    <div className="memberPageName" >
                        <h2>{userNickname}</h2>
                        <div className="memberPageColumn" >
                            <div className="memberPageNameLeft" >

                                <h4>Average Position: {cleanPositionAverage} / {cleanDenom}</h4>
                                <h4>Total Entries: {userWoIDArray.length}</h4>
                                <h4>Top Five: {topFive}</h4>
                            </div>
                            <div className="memberPageNameRight" >
                                <h4>First Place: {firstPlace}</h4>
                                <h4>Second Place: {secondPlace}</h4>
                                <h4>Third Place: {thirdPlace}</h4>

                            </div>
                        </div>
                    </div>
                </div>
                <Table responsive striped bordered hover size="sm" style={{ width: "95%", margin: "auto" }}>
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Station</th>
                            <th>Score</th>
                            <th>Local Average</th>
                            <th>Position</th>
                            <th>Attempts at Location</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData}
                    </tbody>
                </Table>
            </>)
            }
        </div>
    );
}

export default MemberPage;