import { Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import Dashboard from '../components/Dashboard'
import UserList from '../components/UserList'

const PrivateRoute = ({ children }) => {
    let { user } = useContext(AuthContext);
    return user ? children : <Navigate to="/login" />
};

export default PrivateRoute;