import React from 'react';
import Form from 'react-bootstrap/Form'
import react, { useEffect, useState } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useParams } from 'react-router-dom'
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import Nav from 'react-bootstrap/Nav';
import Table from 'react-bootstrap/Table'
import DeleteConfirmation from './DeleteConfirmation';



function WorkoutDetail() {
    const { id } = useParams()
    let { user } = useContext(AuthContext)
    let adminId = user.user_id
    let [adminData, setAdminData] = useState({})
    let [allChow, setAllChow] = useState({})
    let [workoutList, setWorkoutList] = useState({})
    let [stateList, setStateList] = useState({})
    let [locationList, setLocationList] = useState({})
    let [sharedLocList, setSharedLocList] = useState({})
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)
    let [loading, setLoading] = useState(true)
    let [deleteID, setDeleteID] = useState()
    let [deleteMessage, setDeleteMessage] = useState()
    let [deleteName, setDeleteName] = useState()
    let [deleteScore, setDeleteScore] = useState()
    let tableRow
    let extraRow
    // const [clickIndex, setClickIndex] = useState("")
    let clickIndex
    const [showText, setShowText] = useState("Show")
    let [extraRowBool, setExtraRowBool] = useState("")
    const [showBool, setShowBool] = useState(false)
    const [userRow, setUserRow] = useState("")
    const [memberToggle, setMemberToggle] = useState("Member")
    // let memberToggle = "Member"
    function toggleText(user) {
        setShowBool(!showBool)
        if (showBool == false) {
            setMemberToggle("Member/Description")
            setUserRow(user.id)
            extraRow = <>
                <tr>
                    <td>{user.all_1_title}</td>
                    <td>All - 1</td>
                    <td>{user.all_1_score}</td>
                    <td >
                    </td>
                </tr>
                <tr>
                    <td>{user.all_2_title}</td>
                    <td>All - 2</td>
                    <td>{user.all_2_score}</td>
                    <td >
                    </td>
                </tr>
                <tr>
                    <td>{user.all_3_title}</td>
                    <td>All - 3</td>
                    <td>{user.all_3_score}</td>
                    <td >
                    </td>
                </tr>
                <tr>
                    <td>{user.all_4_title}</td>
                    <td>All - 4</td>
                    <td>{user.all_4_score}</td>
                    <td >
                    </td>
                </tr>
                <tr>
                    <td>{user.all_5_title}</td>
                    <td>All - 5</td>
                    <td>{user.all_5_score}</td>
                    <td >
                    </td>
                </tr>
                <tr>
                    <td>{user.all_6_title}</td>
                    <td>All - 6</td>
                    <td>{user.all_6_score}</td>
                    <td >
                    </td>
                </tr>
                <tr>
                    <td>{user.all_7_title}</td>
                    <td>All - 7</td>
                    <td>{user.all_7_score}</td>
                    <td >
                    </td>
                </tr>
                <tr>
                    <td>{user.all_8_title}</td>
                    <td>All - 8</td>
                    <td>{user.all_8_score}</td>
                    <td >
                    </td>
                </tr>
                <tr>
                    <td>{user.all_9_title}</td>
                    <td>All - 9</td>
                    <td>{user.all_9_score}</td>
                    <td >
                    </td>
                </tr>
                <br></br>
            </>
            setShowText("Hide")
            // setExtraRowBool(extraRow)
        } else {
            setMemberToggle("Member")
            setUserRow("")
            extraRow = ""
            setShowText("Show")
        }

    }

    useEffect(() => {
        const fetchData = async () => {
            const { data: adminData } = await axios.get(`${process.env.REACT_APP_API}/adminUsers/${adminId}/`)
            setAdminData(adminData)
            const { data: locationData } = await axios.get(`${process.env.REACT_APP_API}/${id}/${adminData.state}/${adminData.location}/`)
            setLocationList(locationData)
            const { data: stateData } = await axios.get(`${process.env.REACT_APP_API}/${id}/${adminData.state}/`)
            setStateList(stateData)
            const { data: allData } = await axios.get(`${process.env.REACT_APP_API}/${id}/`)
            setWorkoutList(allData)
            const { data: allChow } = await axios.get(`${process.env.REACT_APP_API}/chow_list/`)
            setAllChow(allChow)
            const { data: shareData } = await axios.get(`${process.env.REACT_APP_API}/adminUsers/`)
            setSharedLocList(shareData)
            setLoading(false)
        }
        fetchData()
    }, [])

    let state = null
    let location = null

    let locationArray = Array.from(locationList)
    let locationHeader = ['Position', memberToggle, 'Station', 'Score', 'Delete']
    let stateArray = Array.from(stateList)
    let stateHeader = ['Member', 'Score', 'Location']
    let allArray = Array.from(workoutList)
    let allHeader = ['Member', 'Score', 'Location', 'State']
    let sharedStateArray = []
    let sharedCountryArray = []
    let sharedLocations = []
    if (sharedLocList.length > 0) {
        for (let i = 0; i < sharedLocList.length; i++) {
            sharedLocations.push(sharedLocList[i].location)
        }
    }
    if (sharedLocations) {
        for (let i = 0; i < stateArray.length; i++) {
            for (let j = 0; j < sharedLocations.length; j++) {
                if (stateArray[i].location == sharedLocations[j]) {
                    sharedStateArray.push(stateArray[i])
                }
            }
        }
    }
    if (sharedLocations) {
        for (let i = 0; i < allArray.length; i++) {
            for (let j = 0; j < sharedLocations.length; j++) {
                if (allArray[i].location == sharedLocations[j]) {
                    sharedCountryArray.push(stateArray[i])
                }
            }
        }
    }

    // console.log(sharedStateArray)

    if (locationArray.length > 0 || stateArray.length > 0 || allArray.length > 0) {
        state = adminData.state
        location = adminData.location
    }

    let tableList = []
    let headerList = []

    let averageBanner = null
    let averageBannerSub = null

    let locationTotal = 0
    let locationAverage = 0
    let stateTotal = 0
    let stateAverage = 0
    let allTotal = 0
    let allAverage = 0

    for (let i = 0; i < locationArray.length; i++) {
        locationTotal += locationArray[i].score
    }

    locationAverage = locationTotal / locationArray.length
    let cleanLocationAverage = Math.round(locationAverage)

    for (let i = 0; i < stateArray.length; i++) {
        stateTotal += stateArray[i].score
    }

    stateAverage = stateTotal / stateArray.length
    let cleanStateAverage = Math.round(stateAverage)

    for (let i = 0; i < allArray.length; i++) {
        allTotal += allArray[i].score
    }

    allAverage = allTotal / allArray.length
    let cleanAllAverage = Math.round(allAverage)

    let round = ""
    let roundTitle = ""
    let allChowArray = Array.from(allChow)
    for (let i = 0; i < allChowArray.length; i++) {
        if (allChowArray[i].id == id) {
            roundTitle = allChowArray[i].title
            round = allChowArray[i].round
            i = allChowArray.length - 1
        }
    }

    if (window.location == `https://www.woahtracker.com/WorkoutDetail/${id}/${state}/${location}`) {
        averageBanner = `Total Attempts: ${locationArray.length} - Average Score: ${cleanLocationAverage}`
        averageBannerSub = `State Average: ${cleanStateAverage}`
        headerList = locationHeader.map((column, index) => {
            return <>
                <th>{column}</th>
            </>
        })
        tableList = locationArray.map((user, index) => {
            if (round == "All") {
                extraRow = <>
                    <tr>
                        <td></td>
                        <td>{user.all_1_title}</td>
                        <td>All - 1</td>
                        <td>{user.all_1_score}</td>
                        <td >
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{user.all_2_title}</td>
                        <td>All - 2</td>
                        <td>{user.all_2_score}</td>
                        <td >
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{user.all_3_title}</td>
                        <td>All - 3</td>
                        <td>{user.all_3_score}</td>
                        <td >
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{user.all_4_title}</td>
                        <td>All - 4</td>
                        <td>{user.all_4_score}</td>
                        <td >
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{user.all_5_title}</td>
                        <td>All - 5</td>
                        <td>{user.all_5_score}</td>
                        <td >
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{user.all_6_title}</td>
                        <td>All - 6</td>
                        <td>{user.all_6_score}</td>
                        <td >
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{user.all_7_title}</td>
                        <td>All - 7</td>
                        <td>{user.all_7_score}</td>
                        <td >
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{user.all_8_title}</td>
                        <td>All - 8</td>
                        <td>{user.all_8_score}</td>
                        <td >
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{user.all_9_title}</td>
                        <td>All - 9</td>
                        <td>{user.all_9_score}</td>
                        <td >
                        </td>
                    </tr>
                    <br></br>
                </>
                if (userRow != user.id) {
                    extraRow = ""
                }
                tableRow = <>
                    <tr key={user.id} >
                        <td>{index + 1}</td>
                        <td>
                            <Link to={`/${user.user_id}/MemberPage`} className="descLink">
                                {user.user}
                            </Link>
                        </td>
                        <td>
                            <Link onClick={() => toggleText(user)} className="descLink">
                                {round} (Click to {showText})
                            </Link>
                        </td>
                        <td>{user.score}</td>
                        <td>
                            <Link>
                                <Button size="sm" variant="danger" type="submit" onClick={() => setDeleteID(user.id) & showDeleteModal(user.user, user.score) & setDisplayConfirmationModal(true)} >Delete</Button>
                            </Link>
                        </td>
                        {/* <td><Button size="sm" type=""  >Edit</Button></td> */}
                    </tr>
                    {extraRow}
                </>
            } else {
                tableRow =
                    <tr key={user.id} >
                        <td>{index + 1}</td>
                        <td>
                            <Link to={`/${user.user_id}/MemberPage`} className="descLink">
                                {user.user}
                            </Link>
                        </td>
                        <td>{round}</td>
                        <td>{user.score}</td>
                        <td>
                            <Link>
                                <Button size="sm" variant="danger" type="submit" onClick={() => setDeleteID(user.id) & showDeleteModal(user.user, user.score) & setDisplayConfirmationModal(true)} >Delete</Button>
                            </Link>
                        </td>
                    </tr>
            }
            return <>
                {tableRow}
            </>
        })
    } else if (window.location == `https://www.woahtracker.com/WorkoutDetail/${id}/${state}`) {
        averageBanner = `Total Attempts: ${stateArray.length} - Average Score: ${cleanStateAverage}`
        averageBannerSub = `All Average: ${cleanAllAverage}`
        headerList = stateHeader.map((column, index) => {
            return <>
                <th>{column}</th>
            </>
        })
        tableList = sharedStateArray.map((user, index) => {
            return <tr key={user.id}>
                <td>{user.user}</td>
                <td>{user.score}</td>
                <td>{user.location}</td>
            </tr>
        })
    } else if (window.location == `https://www.woahtracker.com/WorkoutDetail/${id}/`) {
        averageBanner = `Total Attempts: ${allArray.length} - Average Score: ${cleanAllAverage}`
        headerList = allHeader.map((column, index) => {
            return <>
                <th>{column}</th>
            </>
        })
        tableList = sharedCountryArray.map((user, index) => {
            return <tr key={user.id}>
                <td>{user.user}</td>
                <td>{user.score}</td>
                <td>{user.location}</td>
                <td>{user.state}</td>
            </tr>
        })
    }



    const showDeleteModal = (user, score) => {
        setDeleteMessage(`Are you sure you want to delete ${user}'s score of ${score}?`)
        setDisplayConfirmationModal(true);
    };

    const hideConfirmationModal = () => {
        setDeleteMessage()
        setDisplayConfirmationModal(false);
    };

    const submitDelete = async () => {
        await axios.delete(`${process.env.REACT_APP_API}/all_chow/delete/${deleteID}`)
        setDisplayConfirmationModal(false);
        window.location = `https://www.woahtracker.com/WorkoutDetail/${id}/${state}/${location}`
        setDeleteID()
    };

    return (
        <div>
            {loading && <div className='spinner'>
                <Spinner animation="border" role="status" size='lg'>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>}
            {!loading && (<>
                <Nav fill variant="tabs" defaultActiveKey="/Location">
                    <Nav.Item>
                        <Nav.Link as={Link} to={`/WorkoutDetail/${id}/${state}/${location}`} eventKey="/Location" >{location}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to={`/WorkoutDetail/${id}/${state}`} eventKey="/State" >{state}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to={`/WorkoutDetail/${id}/`} eventKey="/Country" >Country</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to={`/WorkoutDetail/${id}/`} eventKey="/All" >All</Nav.Link>
                    </Nav.Item>
                </Nav>
                <br></br>
                <h3>{roundTitle}</h3>
                <h4>{averageBanner}</h4>
                <h5>{averageBannerSub}</h5>
                <br></br>
                <div>
                    <Table responsive striped bordered hover size="sm" style={{ width: "95%", margin: "auto" }}>
                        <thead>
                            <tr>
                                {headerList}
                            </tr>
                        </thead>
                        <tbody>
                            {tableList}
                        </tbody>
                    </Table>
                </div>
            </>)
            }
            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} id={deleteID} message={deleteMessage} />
        </div>
    )






}

export default WorkoutDetail