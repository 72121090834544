import logo from './logo.svg';
import NavBar from './components/NavBar'
import Dashboard from './components/Dashboard'
import UserList from './components/UserList'
import WorkoutList from './components/WorkoutList'
import WorkoutDetail from './components/WorkoutDetail'
import EditUser from './components/EditUser'
import './App.css';
import React, { useEffect, useState, Fragment, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Switch
} from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import AuthContext, { AuthProvider } from "./context/AuthContext";
import Home from "./views/homePage";
import Login from "./views/loginPage";
import Register from "./views/registerPage";
import ProtectedPage from "./views/ProtectedPage";
import About from "./components/About"
import MemberPage from './components/MemberPage';
import ResetPassword from './components/ResetPassword';

function App() {

  return (
    <div className="App">
      <div>
        <Router>
          <AuthProvider>
            <NavBar />
            <Fragment>
              <Routes>
                <Route
                  path="/Dashboard"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/UserList"
                  element={
                    <PrivateRoute>
                      <UserList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/WorkoutList"
                  element={
                    <PrivateRoute>
                      <WorkoutList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/WorkoutDetail/:id"
                  element={
                    <PrivateRoute>
                      <WorkoutDetail />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/WorkoutDetail/:id/:state/:location/"
                  element={
                    <PrivateRoute>
                      <WorkoutDetail />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/WorkoutDetail/:id/:state/"
                  element={
                    <PrivateRoute>
                      <WorkoutDetail />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/EditUser/:id"
                  element={
                    <PrivateRoute>
                      <EditUser />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/:id/MemberPage"
                  element={
                    <PrivateRoute>
                      <MemberPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/resetPassword"
                  element={
                    <PrivateRoute>
                      <ResetPassword />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Login />} />
                <Route path="/about" element={<About />} />
              </Routes>
            </Fragment>
          </AuthProvider>
        </Router>
      </div>
    </div>
  );
}

export default App;
