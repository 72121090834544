import react, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table'
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom'
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

function UserList() {
    let { user } = useContext(AuthContext)
    let id = user.user_id
    let [adminData, setAdminData] = useState({})
    let [loading, setLoading] = useState(true)
    useEffect(() => {
        const fetchData = async () => {
            const { data: adminData } = await axios.get(`${process.env.REACT_APP_API}/adminUsers/${id}/`)
            setAdminData(adminData)
            const { data: response } = await axios.get(`${process.env.REACT_APP_API}/location_users/${adminData.state}/${adminData.location}/`)
            setUserList(response)
            setLoading(false)
        }
        fetchData()
    }, [])

    // if (adminData[0] === null) {
    //     setLoading(true)
    // }
    // console.log(adminData)

    let state = adminData.state
    let location = adminData.location
    let [userList, setUserList] = useState({})
    let [first, setFirst] = useState("")
    let [last, setLast] = useState("")
    let [nickname, setNickname] = useState("")
    let [trainer, setTrainer] = useState("No")
    let defaultNo = `No`

    let currentUsers = Array.from(userList)
    let users = ''
    if (currentUsers) {
        users = currentUsers.map((user, index) => {
            return <tr key={user.id} >
                <td>{user.first}</td>
                <td>{user.last}</td>
                <td>
                    <Link to={`/${user.id}/MemberPage`} className="descLink">
                        {user.nickname}
                    </Link>
                </td>
                <td>{user.trainer}</td>
                {/* <td>
                    <Link to={`/${user.id}/MemberPage`}>
                        <Button size="sm" type="submit">View</Button>
                    </Link>
                </td> */}
                <td>
                    <Link to={`/EditUser/${user.id}`}>
                        <Button size="sm" type="submit">Edit</Button>
                    </Link>
                </td>
            </tr>
        })
    }



    const submitPost = async (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API}/user_list/`, {
            first: first,
            last: last,
            nickname: nickname,
            location: location,
            state: state,
            trainer: trainer
        })
            .then(res => {
                window.location.reload()
            })
            .catch(error => {
                console.log(error.response)
            })

    }

    return (
        <div>
            {loading && <div className='spinner'>
                <Spinner animation="border" role="status" size='lg'>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>}
            {!loading && (<>
                <h1>{adminData.location} User List</h1>
                <br></br>
                <form onSubmit={(e) => submitPost(e)}>
                    <Table responsive striped bordered hover size="sm" style={{ width: "95%", margin: "auto" }}>
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Nickname</th>
                                <th>Trainer</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Form.Control size="sm" type="text" onChange={(e) => setFirst(e.target.value)} required />
                                    <Form.Text >
                                    </Form.Text>
                                </td>
                                <td>
                                    <Form.Control size="sm" type="text" onChange={(e) => setLast(e.target.value)} required />
                                    <Form.Text >
                                    </Form.Text>
                                </td>
                                <td>
                                    <Form.Control size="sm" type="text" onChange={(e) => setNickname(e.target.value)} required />
                                    <Form.Text >
                                    </Form.Text>
                                </td>
                                <td>
                                    <Form.Select size="sm" aria-label="Is Trainer?" onChange={(e) => setTrainer(e.target.value)} value={trainer}>
                                        <option>No</option>
                                        <option>Yes</option>
                                    </Form.Select>
                                </td>
                                <td>
                                    <Button size="sm" type="submit"  >Add</Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </form>
                <br></br>
                <Table responsive striped bordered hover size="sm" style={{ width: "95%", margin: "auto" }}>
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Nickname</th>
                            <th>Trainer</th>
                            {/* <th>Profile</th> */}
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users}
                    </tbody>
                </Table>
            </>)
            }
        </div >
    )
}

export default UserList