import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Button, FloatingLabel, Form } from "react-bootstrap";

const LoginPage = () => {
    const { loginUser } = useContext(AuthContext);
    const handleSubmit = e => {
        e.preventDefault();
        const username = e.target.username.value;
        const password = e.target.password.value;
        username.length > 0 && loginUser(username, password);
    };

    return (
        <div className="loginBox">
            <form onSubmit={handleSubmit}>
                <h2>Login </h2>
                <div className="userName">
                    <FloatingLabel
                        controlId="username"
                        label="Username"
                        className="mb-3"
                    >
                        <Form.Control type="text" placeholder="Username" required />
                    </FloatingLabel>
                </div>
                <div className="passWord">
                    <FloatingLabel
                        controlId="password"
                        label="Password"
                        className="mb-3"
                    >
                        <Form.Control type="password" placeholder="Password" required />
                    </FloatingLabel>
                </div>
                <div className="loginButton">
                    <Button size="mb-3" type="submit"  >Login</Button>
                </div>
            </form >
            <br></br>
            <h6><a href="/about">Click Here for More Info</a></h6>
        </div >
    );
};

export default LoginPage;